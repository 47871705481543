import React from "react"
import lostStyles from "./404.module.scss"

export default function NotFoundPage() {
    return (
        <div className={lostStyles.container2} style={{ height: "100vh", width: "100wh" }}>
            <div className={lostStyles.container}>
                <div className={lostStyles.glitch} data-text="Error.404">Error.404</div>
                <div className={lostStyles.glow}>Error.404</div>
                    <p className={lostStyles.subtitle}>This page does not exists</p>
            </div>
            <div className={lostStyles.scanlines}></div>
        </div>
    )
}
